// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.


//
// Override Bootstrap here
//

// Toggle global options
$enable-gradients: false;
$enable-shadows: true;
$enable-responsive-font-sizes: true;


// Customize some defaults
$theme-colors: (
  "primary": #00aeef,
  "secondary": #58595b,
  "cyan": #00aeef,
  "magenta": #ec008c,
  "slate": #004d6d
);

$body-color: #58595b;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1340px
);

$font-size-base: 1.1rem;



//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

// @import "bootstrap/scss/bootstrap";

// Option 2
//
// Import just the styles you need. Note that some stylesheets are required no matter what.

@import "bootstrap/scss/functions"; // Required
@import "bootstrap/scss/variables"; // Required
@import "bootstrap/scss/mixins"; // Required
// @import "bootstrap/scss/root";
@import "bootstrap/scss/reboot"; // Required
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
// @import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
// @import "bootstrap/scss/tables";
// @import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
// @import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
// @import "bootstrap/scss/input-group"; // Requires forms
// @import "bootstrap/scss/custom-forms";
// @import "bootstrap/scss/nav";
// @import "bootstrap/scss/navbar"; // Requires nav
@import "bootstrap/scss/card";
// @import "bootstrap/scss/breadcrumb";
// @import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/jumbotron";
// @import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/media";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal"; // Requires transitions
// @import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
@import "bootstrap/scss/utilities";
// @import "bootstrap/scss/print";



//
// Custom styles
//

h1, h2, h3, h4, h5, h6 {
  font-family: proxima-nova-condensed, sans-serif;
  font-weight: 800;
  font-style: normal;
  color: #004d6d;
}

h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
  text-decoration: none;
}

p {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;  
  font-style: normal;
}

.jumbotron h1 {
  font-size: 4.5rem;
  line-height: 1.0;
}

.jumbotron p {
  font-size: 1.7rem;
}

h2.label {
  font-size: 2rem;
  margin: 3rem 0;
}

h2.label.contact-cta {
  margin: 1rem 0;
}

h3.card-title {
  font-size: 2.5rem;
}

.btn-primary {
  font-family: proxima-nova, sans-serif;
  font-weight: 800 !important;
  text-transform: uppercase;
}

.badge-tertiary {
  color: #fff;
  background-color: #bbb;
}

.jumbotron {
  background-color: #fff;
  margin-bottom: 0;
}

.label {
  font-family: proxima-nova, sans-serif;
  font-weight: 900;
  font-style: normal;
  text-transform: uppercase;
}

.contact-logo {
  width: 50px;
  margin: .75em;
  transition: all .15s ease-in-out;
  opacity: 0.6;
}

.contact-logo:hover {
  transform: scale(1.1);
  opacity: 1.0;
}

.design-cta {
  font-size: 1.0rem;
}

.design-cta a {
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-style: normal;
  opacity: 0.6;
}

.design-cta a:hover {
  text-decoration: none;
  color: #00aeef;
  opacity: 1.0;
}

@media (min-width: 576px) {
  .jumbotron { padding: 5rem 2rem 3rem 2rem; }
}

@media (max-width: 992px) {
  .jumbotron h1 { font-size: 4rem; }
  .jumbotron p { font-size: 1.6rem; }
}

@media (max-width: 768px) {
  .jumbotron h1 { font-size: 3.5rem; }
  .jumbotron p { font-size: 1.5rem; }
}

@media (max-width: 600px) {
  .jumbotron h1 { font-size: 3.0rem; }
  .jumbotron p { font-size: 1.3rem; }
}
